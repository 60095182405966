<template>
  <div
    :class="{ 'horizontal-padding': mode !== 'intro' }"
  >
    <h1 v-if="mode !== 'intro'">
      Sign Up
    </h1>

    <spinner
      v-if="signupDataStatus === 'loading'"
      preset="large"
    />

    <template v-else-if="signupDataStatus === 'loaded'">
      <alert
        v-if="ipg.username !== undefined && mode === 'in_progress'"
        class="ipg"
      >
        <p>You are signing up under:</p>

        <div>
          <profile-image :user="ipg" />

          <span>
            {{ ipg.first_name }} {{ ipg.last_name }}
          </span>
        </div>
      </alert>

      <template v-if="mode === 'intro'">
        <div class="horizontal-padding">
          <div
            v-if="ipg.username !== undefined"
            class="connecting-with"
          >
            <profile-image
              class="profile-image"
              :user="ipg"
              :size="128"
              bordered
            />

            <div class="below-profile-image">
              <div>You're connecting with</div>
              <span>{{ ipg.first_name }} {{ ipg.last_name }}</span>
            </div>
          </div>

          <div class="text-and-video">
            <div class="text-and-video-text">
              <h2>The electronic sports tournament platform</h2>

              <p>
                Where any video game player can become a mentor or find a mentor
                and become professional.
              </p>
            </div>

            <div class="text-and-video-video-left embed-container">
              <iframe
                src="https://www.youtube.com/embed/1y15DUf-Bmw"
                frameborder="0"
                allowfullscreen
              />
            </div>
          </div>

          <div class="text-and-video">
            <div class="text-and-video-text">
              <h2>The Independent Professional Gamer</h2>

              <p>As an IPG, growing your network also grows your pocket book.</p>
            </div>

            <div class="embed-container">
              <iframe
                src="https://www.youtube.com/embed/eDy5nOpHDVc"
                frameborder="0"
                allowfullscreen
              />
            </div>
          </div>

          <div class="text-and-video">
            <div class="text-and-video-text">
              <h2>How The Players Arena helps you make money</h2>

              <p>
                Becoming an Independent Professional Gamer gives you the
                opportunity to earn money by mentoring others.
              </p>
            </div>

            <div class="text-and-video-video-left embed-container">
              <iframe
                src="https://www.youtube.com/embed/c80PojTc9C4"
                frameborder="0"
                allowfullscreen
              />
            </div>
          </div>
        </div>

        <div class="sign-up-banner">
          <div>
            <img
              src="@/assets/sign-up-banner.jpg"
              alt
              class="img-fluid"
            >
          </div>

          <button
            type="button"
            class="btn"
            @click="introSignUpButtonClicked"
          >
            Sign Up
          </button>
        </div>

        <div class="horizontal-padding faq">
          <faq />
        </div>
      </template>

      <template v-else-if="formFields.signup_type === ''">
        <p class="text-center">
          Which type of account would you like to sign up for? You can switch
          your account type later on if you change your mind.
        </p>

        <table>
          <caption class="visually-hidden">
            Comparison of the two account types
          </caption>

          <thead>
            <tr>
              <th aria-hidden="true" />
              <th>Customer</th>
              <th>
                IPG
                <span>(Independent Professional Gamer)</span>
              </th>
              <th>
                Self-subscribing IPG
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Registration Fee</th>
              <td>No Registration Fee</td>
              <td>${{ formatCurrency(pricing.ipg_registration) }}</td>
              <td>${{ formatCurrency(pricing.ipg_registration) }}</td>
            </tr>
            <tr>
              <th>Monthly Subscription Fee</th>
              <td>${{ formatCurrency(pricing.customer_subscription) }}</td>
              <td>No Monthly Fee</td>
              <td>${{ formatCurrency(pricing.customer_subscription_reduced) }}</td>
            </tr>
            <tr>
              <th>Promote yourself with a professional gamer profile</th>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  No
                </div>
                <x-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
            </tr>
            <tr>
              <th>Join, create, and manage teams</th>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  No
                </div>
                <x-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
            </tr>
            <tr>
              <th>Use calendars</th>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  No
                </div>
                <x-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
            </tr>
            <tr>
              <th>Become a coach or find a coach</th>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  No
                </div>
                <x-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
            </tr>
            <tr>
              <th>Update your feed and follow other users</th>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  No
                </div>
                <x-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
            </tr>
            <tr>
              <th>Mentor players into The Players Arena genealogy tree</th>
              <td>
                <div class="visually-hidden">
                  No
                </div>
                <x-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
            </tr>
            <tr>
              <th>Sign up players as customers</th>
              <td>
                <div class="visually-hidden">
                  No
                </div>
                <x-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
            </tr>
            <tr>
              <th>Get paid for each active customer or IPG under you</th>
              <td>
                <div class="visually-hidden">
                  No
                </div>
                <x-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
            </tr>
            <tr>
              <th>Access to training and documents</th>
              <td>
                <div class="visually-hidden">
                  No
                </div>
                <x-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
              <td>
                <div class="visually-hidden">
                  Yes
                </div>
                <check-icon />
              </td>
            </tr>
            <tr>
              <th />
              <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="setSignupType('customer')"
                >
                  Choose
                </button>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="setSignupType('ipg')"
                >
                  Choose
                </button>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="setSignupType('both')"
                >
                  Choose
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </template>

      <div
        v-else
        class="sign-up-type-chosen-content"
      >
        <h2>
          Account Type:

          <template v-if="formFields.signup_type === 'customer'">
            Customer
          </template>

          <template v-else>
            <template v-if="formFields.signup_type === 'both'">
              Self-subscribing
            </template>

            Independent Professional Gamer
          </template>
        </h2>

        <alert
          v-if="mode === 'complete'"
          variant="success"
        >
          Thank you for signing up! Before continuing, we need to verify your
          email address. Please check your email and visit the included link.
        </alert>

        <template v-else>
          <alert
            v-if="formErrors.non_field_errors !== undefined"
            variant="danger"
            class="js-form-error"
            dismissible
            @dismissed="formErrors = {}"
          >
            {{ formErrors.non_field_errors[0] }}
          </alert>

          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="first_name">First name</label>
              <input
                id="first_name"
                v-model="formFields.first_name"
                :class="{ 'is-invalid': formErrors.first_name !== undefined }"
                type="text"
                maxlength="30"
                required
              >
              <div
                v-if="formErrors.first_name !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.first_name[0] }}
              </div>
            </div>

            <div class="form-group">
              <label for="last_name">Last name</label>
              <input
                id="last_name"
                v-model="formFields.last_name"
                :class="{ 'is-invalid': formErrors.last_name !== undefined }"
                type="text"
                maxlength="150"
                required
              >
              <div
                v-if="formErrors.last_name !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.last_name[0] }}
              </div>
            </div>

            <div class="form-group">
              <label for="username">Username</label>
              <input
                id="username"
                v-model="formFields.username"
                :class="{ 'is-invalid': formErrors.username !== undefined }"
                type="text"
                maxlength="150"
                required
              >
              <div
                v-if="formErrors.username !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.username[0] }}
              </div>
            </div>

            <div class="form-group">
              <label for="email1">Email address</label>
              <input
                id="email1"
                v-model="formFields.email1"
                :class="{ 'is-invalid': formErrors.email1 !== undefined }"
                type="email"
                maxlength="254"
                required
              >
              <div
                v-if="formErrors.email1 !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.email1[0] }}
              </div>
            </div>

            <div class="form-group">
              <label for="email2">Email address confirmation</label>
              <input
                id="email2"
                v-model="formFields.email2"
                :class="{ 'is-invalid': formErrors.email2 !== undefined }"
                type="email"
                maxlength="254"
                required
              >
              <div
                v-if="formErrors.email2 !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.email2[0] }}
              </div>
            </div>

            <div class="form-group">
              <label for="password1">Password</label>
              <input
                id="password1"
                v-model="formFields.password1"
                :class="{ 'is-invalid': formErrors.password1 !== undefined }"
                type="password"
                minlength="8"
                required
                aria-describedby="password1_help"
              >
              <small
                id="password1_help"
                class="form-text"
              >
                Your password must contain at least 8 characters.
              </small>
              <div
                v-if="formErrors.password1 !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.password1[0] }}
              </div>
            </div>

            <div class="form-group">
              <label for="password2">Password confirmation</label>
              <input
                id="password2"
                v-model="formFields.password2"
                :class="{ 'is-invalid': formErrors.password2 !== undefined }"
                type="password"
                minlength="8"
                required
              >
              <div
                v-if="formErrors.password2 !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.password2[0] }}
              </div>
            </div>

            <div class="form-group">
              <label for="phone">Phone number (optional)</label>
              <input
                id="phone"
                v-model="formFields.phone"
                :class="{ 'is-invalid': formErrors.phone !== undefined }"
                type="tel"
                maxlength="30"
              >
              <div
                v-if="formErrors.phone !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.phone[0] }}
              </div>
            </div>

            <div class="form-check">
              <input
                id="agree"
                v-model="formFields.agree"
                type="checkbox"
                class="form-check-input"
                :class="{ 'is-invalid': formErrors.agree !== undefined }"
              >
              <label
                for="agree"
                class="form-check-label"
              >
                I have read the privacy policy and accept the practices set
                forth in it. I have also read the terms of use and agree to
                abide by those terms.
              </label>
              <small class="form-text privacy-and-terms">
                Read the

                <router-link
                  :to="{ name: 'document', params: { documentSlug: 'privacy-policy' } }"
                  target="_blank"
                >
                  privacy policy
                </router-link>

                and

                <router-link
                  :to="{ name: 'document', params: { documentSlug: 'terms-service' } }"
                  target="_blank"
                >
                  terms of use
                </router-link>
              </small>
              <div
                v-if="formErrors.agree !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ formErrors.agree[0] }}
              </div>
            </div>

            <div class="buttons">
              <button
                type="button"
                class="btn btn-outline-light"
                @click="setSignupType('')"
              >
                Back
              </button>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="formSubmitting"
              >
                <template v-if="formSubmitting">
                  Processing
                  <spinner />
                </template>

                <template v-else>
                  Sign Up
                </template>
              </button>
            </div>
          </form>
        </template>
      </div>
    </template>

    <template v-else-if="signupDataStatus === 'error'">
      <alert variant="danger">
        An error occurred loading the signup data. Please check your connection
        and try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="loadSignupData"
      >
        Try Again
      </button>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { CheckIcon, XIcon } from '@zhuowenli/vue-feather-icons';
import Faq from '@/components/Faq.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';

export default defineComponent({
  components: {
    CheckIcon,
    XIcon,
    Faq,
    ProfileImage,
  },
  async beforeRouteEnter(to, from, next) {
    await new Promise<void>((resolve) => {
      const appNotYetLaunchedContent = document.querySelector('.app-not-yet-launched-content');

      if (appNotYetLaunchedContent) {
        const observer = new MutationObserver(() => {
          observer.disconnect();
          resolve();
        });

        observer.observe(appNotYetLaunchedContent, { attributes: true });
      } else {
        resolve();
      }
    });

    if (document.querySelector('.is-anonymous-or-not-onboarded')) {
      next();
    } else {
      next({ name: 'feed' });
    }
  },
  props: {
    username: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    signupDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    mode: 'intro' as 'intro' | 'in_progress' | 'complete',
    pricing: {
      ipg_registration: 0,
      customer_subscription: 0,
      customer_subscription_reduced: 0,
    },
    ipg: {} as Record<string, string>,
    invitationToken: '',
    formFields: {
      signup_type: '',
      first_name: '',
      last_name: '',
      username: '',
      email1: '',
      email2: '',
      password1: '',
      password2: '',
      phone: '',
      agree: false,
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadSignupData();
  },
  methods: {
    async loadSignupData() {
      this.signupDataStatus = 'loading';

      let url = 'users/signup_data/';

      if (this.username !== '') {
        url += `?username=${this.username}`;
      }

      const responseData = await this.api({ url });

      if (responseData.status === 200) {
        this.pricing = responseData.body.pricing;

        if (responseData.body.ipg !== undefined) {
          this.ipg = responseData.body.ipg;

          const currentRoute = this.$router.currentRoute.value;
          const token = currentRoute.query.invitation;

          if (token !== undefined) {
            this.api({
              url: `business-portal/invitation_visited/?username=${this.username}&token=${token}`,
              method: 'POST',
            });

            this.invitationToken = token as string;
          }
        }

        this.signupDataStatus = 'loaded';
      } else {
        this.signupDataStatus = 'error';
      }
    },
    introSignUpButtonClicked() {
      this.mode = 'in_progress';
      window.scrollTo(0, 0);
    },
    setSignupType(signupType: string) {
      this.formFields.signup_type = signupType;
      window.scrollTo(0, 0);
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const json = JSON.parse(JSON.stringify(this.formFields));

      if (this.ipg.username !== undefined) {
        json.signed_up_under = this.ipg.username;

        if (this.invitationToken !== '') {
          json.invitation_token = this.invitationToken;
        }
      }

      const responseData = await this.api({
        url: 'users/',
        method: 'POST',
        json,
      });

      this.formSubmitting = false;

      if (responseData.status === 201) {
        this.mode = 'complete';
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .ipg {
    margin: 0 auto 1rem;
    width: 290px;
    text-align: center;

    > div {
      display: flex;
      gap: 0.25rem;
      justify-content: center;
      align-items: center;

      > span {
        min-width: 0;
      }
    }
  }

  .connecting-with {
    text-align: center;
  }

  .below-profile-image {
    position: relative;
    margin-top: 60px;
    font-size: 1.3rem;
    word-wrap: break-word;

    &:before {
      content: "";
      position: absolute;
      top: -61px;
      left: 50%;
      transform: translateX(-50%);
      width: 3px;
      height: 60px;
      background-color: var(--blue);
    }

    span {
      font-size: inherit;
      font-weight: bold;
      color: var(--blue);
    }
  }

  .text-and-video,
  .sign-up-banner,
  .faq {
    margin-top: 50px;
  }

  .text-and-video-text p {
    font-size: 1.1rem;
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    max-width: 100%;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .sign-up-banner {
    position: relative;

    div {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--blue-a50);
      }
    }

    img {
      vertical-align: middle;
    }

    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0.7rem 1.6rem;
      font-size: 1.3rem;
      font-weight: bold;
      background-color: #fff;
      border-width: 3px;
      text-transform: uppercase;

      &:focus,
      &:hover {
        border-color: var(--green);
      }
    }
  }

  table {
    margin: 0 auto;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 0.25rem;
  }

  td {
    text-align: center;
  }

  span {
    display: block;
    font-size: 0.7rem;
  }

  thead th {
    vertical-align: bottom;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: var(--gray-darker);
    }

    th {
      text-align: right;
    }
  }

  .feather-check {
    color: var(--green);
  }

  .feather-x {
    color: var(--red);
  }

  .sign-up-type-chosen-content {
    margin: 0 auto;
    width: 290px;
  }

  .privacy-and-terms a {
    color: var(--blue);
  }

  .buttons {
    margin-top: 0.5rem;
    text-align: center;
  }

  @media (min-width: 720px) {
    .text-and-video,
    .faq {
      margin-right: 90px;
      margin-left: 90px;
    }
  }

  @media (min-width: 1024px) {
    .text-and-video {
      display: grid;
      grid-gap: 90px;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }

    .text-and-video-text h2 {
      text-align: left;
    }

    .text-and-video-video-left {
      order: -1;
    }
  }

  @media (max-width: 1023.98px) {
    .text-and-video-text p {
      text-align: center;
    }
  }

  @media (max-width: 479.98px) {
    table {
      font-size: 0.7rem;

      .btn {
        padding: 0.25rem;
      }
    }
  }
</style>
