
import { defineComponent } from 'vue';
import { CheckIcon, XIcon } from '@zhuowenli/vue-feather-icons';
import Faq from '@/components/Faq.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';

export default defineComponent({
  components: {
    CheckIcon,
    XIcon,
    Faq,
    ProfileImage,
  },
  async beforeRouteEnter(to, from, next) {
    await new Promise<void>((resolve) => {
      const appNotYetLaunchedContent = document.querySelector('.app-not-yet-launched-content');

      if (appNotYetLaunchedContent) {
        const observer = new MutationObserver(() => {
          observer.disconnect();
          resolve();
        });

        observer.observe(appNotYetLaunchedContent, { attributes: true });
      } else {
        resolve();
      }
    });

    if (document.querySelector('.is-anonymous-or-not-onboarded')) {
      next();
    } else {
      next({ name: 'feed' });
    }
  },
  props: {
    username: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    signupDataStatus: 'loading' as 'loading' | 'loaded' | 'error',
    mode: 'intro' as 'intro' | 'in_progress' | 'complete',
    pricing: {
      ipg_registration: 0,
      customer_subscription: 0,
      customer_subscription_reduced: 0,
    },
    ipg: {} as Record<string, string>,
    invitationToken: '',
    formFields: {
      signup_type: '',
      first_name: '',
      last_name: '',
      username: '',
      email1: '',
      email2: '',
      password1: '',
      password2: '',
      phone: '',
      agree: false,
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.loadSignupData();
  },
  methods: {
    async loadSignupData() {
      this.signupDataStatus = 'loading';

      let url = 'users/signup_data/';

      if (this.username !== '') {
        url += `?username=${this.username}`;
      }

      const responseData = await this.api({ url });

      if (responseData.status === 200) {
        this.pricing = responseData.body.pricing;

        if (responseData.body.ipg !== undefined) {
          this.ipg = responseData.body.ipg;

          const currentRoute = this.$router.currentRoute.value;
          const token = currentRoute.query.invitation;

          if (token !== undefined) {
            this.api({
              url: `business-portal/invitation_visited/?username=${this.username}&token=${token}`,
              method: 'POST',
            });

            this.invitationToken = token as string;
          }
        }

        this.signupDataStatus = 'loaded';
      } else {
        this.signupDataStatus = 'error';
      }
    },
    introSignUpButtonClicked() {
      this.mode = 'in_progress';
      window.scrollTo(0, 0);
    },
    setSignupType(signupType: string) {
      this.formFields.signup_type = signupType;
      window.scrollTo(0, 0);
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const json = JSON.parse(JSON.stringify(this.formFields));

      if (this.ipg.username !== undefined) {
        json.signed_up_under = this.ipg.username;

        if (this.invitationToken !== '') {
          json.invitation_token = this.invitationToken;
        }
      }

      const responseData = await this.api({
        url: 'users/',
        method: 'POST',
        json,
      });

      this.formSubmitting = false;

      if (responseData.status === 201) {
        this.mode = 'complete';
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
