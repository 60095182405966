
import { defineComponent } from 'vue';
import { MinusCircleIcon, PlusCircleIcon } from '@zhuowenli/vue-feather-icons';
import TransitionSlide from '@/components/TransitionSlide.vue';

export default defineComponent({
  components: {
    MinusCircleIcon,
    PlusCircleIcon,
    TransitionSlide,
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    faqs: [] as Array<Array<{
      id: number;
      question: string;
      answer: string;
    }>>,
    visibleAnswers: [] as Array<number>,
  }),
  created() {
    this.loadFAQs();
  },
  methods: {
    async loadFAQs() {
      this.status = 'loading';

      const responseData = await this.api({
        url: 'faqs/',
      });

      if (responseData.status === 200) {
        const faqs = responseData.body;

        const half = Math.ceil(faqs.length / 2);
        this.faqs = [faqs.slice(0, half), faqs.slice(-half)];
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
    toggleAnswer(idToToggle: number) {
      if (this.visibleAnswers.includes(idToToggle)) {
        this.visibleAnswers = this.visibleAnswers.filter((i) => i !== idToToggle);
      } else {
        this.visibleAnswers.push(idToToggle);
      }
    },
  },
});
