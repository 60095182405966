<template>
  <h1>FAQ</h1>

  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <div
    v-else-if="status === 'loaded'"
    class="faqs"
  >
    <div
      v-for="(faqHalf, index) of faqs"
      :key="`faqHalf${index}`"
    >
      <div
        v-for="faq of faqHalf"
        :key="`faq${faq.id}`"
        class="faq"
      >
        <button
          type="button"
          class="btn-transparent"
          :aria-controls="`answer${faq.id}`"
          :aria-expanded="visibleAnswers.includes(faq.id)"
          @click="toggleAnswer(faq.id)"
        >
          <minus-circle-icon v-if="visibleAnswers.includes(faq.id)" />

          <plus-circle-icon v-else />

          {{ faq.question }}
        </button>

        <transition-slide mode="out-in">
          <div
            v-show="visibleAnswers.includes(faq.id)"
            :id="`answer${faq.id}`"
            class="answer"
            v-html="faq.answer"
          />
        </transition-slide>
      </div>
    </div>
  </div>

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      Unable to load the FAQs. Please check your connection and try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadFAQs"
    >
      Try Again
    </button>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MinusCircleIcon, PlusCircleIcon } from '@zhuowenli/vue-feather-icons';
import TransitionSlide from '@/components/TransitionSlide.vue';

export default defineComponent({
  components: {
    MinusCircleIcon,
    PlusCircleIcon,
    TransitionSlide,
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    faqs: [] as Array<Array<{
      id: number;
      question: string;
      answer: string;
    }>>,
    visibleAnswers: [] as Array<number>,
  }),
  created() {
    this.loadFAQs();
  },
  methods: {
    async loadFAQs() {
      this.status = 'loading';

      const responseData = await this.api({
        url: 'faqs/',
      });

      if (responseData.status === 200) {
        const faqs = responseData.body;

        const half = Math.ceil(faqs.length / 2);
        this.faqs = [faqs.slice(0, half), faqs.slice(-half)];
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
    toggleAnswer(idToToggle: number) {
      if (this.visibleAnswers.includes(idToToggle)) {
        this.visibleAnswers = this.visibleAnswers.filter((i) => i !== idToToggle);
      } else {
        this.visibleAnswers.push(idToToggle);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .faqs {
    display: grid;
    grid-gap: 1rem;

    > div {
      min-width: 0;
    }
  }

  .faq {
    word-wrap: break-word;

    button {
      padding-left: 1.5em;
      font-size: 1.1rem;
      text-align: left;
      text-indent: -1.5em;
      border-bottom: 2px solid transparent;
      transition: border-color 0.15s ease-in-out;

      &:focus,
      &:hover {
        border-color: var(--blue);
      }

      &:focus {
        outline: 0;
      }
    }

    + .faq {
      margin-top: 1rem;
    }
  }

  .feather {
    margin-right: 0.25rem;
    width: 18px;
    height: 18px;
    color: var(--blue);
  }

  .answer {
    font-size: 1rem;

    :deep(p:last-child) {
      margin-bottom: 0;
    }
  }

  @media (min-width: 568px) {
    .faqs {
      grid-template-columns: 1fr 1fr;
    }
  }
</style>
